footer {
  width: 100%;
  background: #1d1f24;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

footer .overlay {
  width: 100%;
  background-image: url("pattern-wave.a271c9d8.svg");
  background-position: center;
  background-repeat: repeat;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

footer .overlay .content {
  padding-top: 59px;
  padding-bottom: 59px;
  display: flex;
}

footer .overlay .content .right_column {
  width: 50%;
}

footer .overlay .content .right_column p {
  max-width: 376px;
  padding: 32px 0;
  font-weight: 500;
}

footer .overlay .content .right_column .social_media img {
  height: 30px;
  width: 26px;
  margin-right: 10px;
}

footer .overlay .content .left_column {
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

footer .overlay .content .left_column .title {
  color: #bbbcbd;
  padding-bottom: 32px;
  font-weight: 400;
}

footer .overlay .content .left_column .list {
  padding-left: 0;
}

footer .overlay .content .left_column .list li {
  margin-bottom: 16px;
  font-weight: 400;
  list-style-type: none;
}

footer .overlay .content .left_column .list li a {
  color: #fff;
  text-decoration: none;
}

footer .overlay .content .left_column .list li:last-of-type {
  margin-bottom: 0;
}

footer .copyright {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  display: flex;
}

@media (max-width: 991.98px) {
  .content {
    flex-direction: column;
  }

  footer .overlay .content .right_column {
    width: 100%;
  }

  footer .overlay .content .right_column p {
    max-width: 720px;
  }

  footer .overlay .content .left_column {
    width: 100%;
  }

  footer .overlay .content .left_column .title {
    padding-top: 48px;
  }
}

@media (max-width: 575.98px) {
  footer .copyright {
    flex-direction: column;
    align-items: baseline;
    padding-bottom: 0;
  }

  footer .copyright .select {
    margin: 24px 0;
  }

  footer .overlay .content .left_column {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.adb3e56e.css.map */
