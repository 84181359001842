@import 'common/variables.scss';
@import 'common/mixins.scss';

footer {
  width: 100%;
  @include flex-column(center, center);
  background: $bg;

  .overlay {
    width: 100%;
    @include flex-row(center, null);
    background-image: url('../assets/pattern-wave.svg');
    background-position: center;
    background-repeat: repeat;

    .content {
      display: flex;
      padding-top: 59px;
      padding-bottom: 59px;

      .right_column {
        width: 50%;

        p {
          max-width: 376px;
          padding: 32px 0;
          font-weight: $medium-font-weight;
        }

        .social_media {
          img {
            height: 30px;
            width: 26px;
            margin-right: 10px;
          }
        }
      }

      .left_column {
        @include flex-row(space-between, null);
        width: 50%;

        .title {
          font-weight: $regular-font-weight;
          color: $text-secondary;
          padding-bottom: 32px;
        }

        .list {
          padding-left: 0;

          li {
            font-weight: $regular-font-weight;
            list-style-type: none;
            margin-bottom: 16px;
            a {
              text-decoration: none;
              color: $text-color;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .copyright {
    @include flex-row(space-between, center);
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: $regular-font-weight;
  }
}

/*breakpoints*/
@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .content {
    flex-direction: column;
  }
  footer .overlay .content .right_column {
    width: 100%;
    p {
      max-width: 720px;
    }
  }

  footer .overlay .content .left_column {
    width: 100%;
    .title {
      padding-top: 48px;
    }
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  footer {
    .copyright {
      flex-direction: column;
      align-items: baseline;
      padding-bottom: 0;

      .select {
        margin: 24px 0;
      }
    }
  }

  footer .overlay .content .left_column {
    flex-direction: column;
  }
}
